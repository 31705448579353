<template>
  <div eagle-form-item="selection">
    <datetime-picker
      :format="format"
      :readonly="readonly"
      :disabled="disabled"
      :clearable="clearable"
      v-model="data"
    ></datetime-picker>
  </div>
</template>

<script>
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.data = window.eagleLodash.cloneDeep(this.storeData)
    },
  },
  computed: {
    format() {
      return this.formDataItem.format || 'datetime'
    },
    clearable() {
      return this.formDataItem.clearable === true
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.sync()
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
